import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Our 2nd Annual Pre Open in house competition will start today! RX Men,
RX Women, scaled, and masters (50+) divisions. If you would like to
register please email Daniel at: Daniel\\@crossfitlouisvilleeast.com with
which division you’d like to enter. Each workout will be a daily WOD and
you’ll be able to do it during class so there’s no reason not to enter!
Each workout will also be done only once so unlike the Open it won’t
interfere with our regular programming.`}</strong></p>
    <p><em parentName="p">{`Pre Open WOD 1`}</em></p>
    <p>{`15:00 AMRAP of:`}</p>
    <p>{`15-Bench Press (135/80)`}</p>
    <p>{`15-Pullups`}</p>
    <p>{`12-Bench Press`}</p>
    <p>{`12-Pullups`}</p>
    <p>{`9-Bench Press`}</p>
    <p>{`9-Pullups`}</p>
    <p>{`50 Double Unders`}</p>
    <p>{`12-Bench Press (185/110)`}</p>
    <p>{`12-CTB Pullups`}</p>
    <p>{`9-Bench Press`}</p>
    <p>{`9-CTB Pullups`}</p>
    <p>{`6-Bench Press`}</p>
    <p>{`6-CTB Pullups`}</p>
    <p>{`50 Double Unders`}</p>
    <p>{`9-Bench Press (225/135)`}</p>
    <p>{`9-Bar Muscle Ups`}</p>
    <p>{`6-Bench Press`}</p>
    <p>{`6-Bar Muscle Ups`}</p>
    <p>{`3-Bench Press`}</p>
    <p>{`3-Bar Muscle Ups`}</p>
    <p>{`50 Double Unders`}</p>
    <p><em parentName="p">{`*`}{`With remaining time perform as many bench press repetitions as
possible with 225/135.  Score = total reps.`}</em></p>
    <p><strong parentName="p">{`Scaled & Masters Divisions`}</strong></p>
    <p>{`15:00 AMRAP of:`}</p>
    <p>{`15-Bench Press (105/65)`}</p>
    <p>{`15-Ring Rows (leg straight, feet under rings while hanging at rest)`}</p>
    <p>{`12-Bench Press`}</p>
    <p>{`12-Ring Rows`}</p>
    <p>{`9-Bench Press`}</p>
    <p>{`9-Ring Rows`}</p>
    <p>{`100 Jump Rope Singles`}</p>
    <p>{`12-Bench Press (135/80)`}</p>
    <p>{`12-Jumping Pullups (bar 12″ above head)`}</p>
    <p>{`9-Bench Press`}</p>
    <p>{`9-Jumping Pullups`}</p>
    <p>{`6-Bench Press`}</p>
    <p>{`6-Jumping Pullups`}</p>
    <p>{`100 Jump Rope Singles`}</p>
    <p>{`9-Bench Press (165/100)`}</p>
    <p>{`9-Jumping CTB Pullups (bar 12″ above head)`}</p>
    <p>{`6-Bench Press`}</p>
    <p>{`6-Jumping CTB’s`}</p>
    <p>{`3-Bench Press`}</p>
    <p>{`3-Jumping CTB’s`}</p>
    <p>{`100 Singles`}</p>
    <p><em parentName="p">{`*`}{`With remaining time perform as many bench press repetitions as
possible with 165/100. Score = total reps.`}</em></p>
    <p><em parentName="p">{`*`}{`You must load your own bar, judges will spot on the bench and count
reps only.`}</em></p>
    <p><em parentName="p">{`*`}{`If you have remaining time but cannot complete another rep your judge
can record your score and you can continue for the remaining time with
the lower weight or lower skill bar movement but the reps will not count
toward your score.`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`*`}{`If time remains in class…`}</em></strong></p>
    <p>{`Shoulder Press 4-4-4-4\\@85% 1RM`}</p>
    <p>{`DB Rows 4-4-4-4`}</p>
    <p><strong parentName="p">{`*`}{`The next Strength Program will start January 7th. For more
information email Eric at: Fallscitystrength\\@gmail.com.`}</strong></p>
    <p><strong parentName="p">{`*`}{`Saturday, February 4th, we will have our 3rd Annual Sports Nutrition
seminar will Dawn from Sports Nutrition 2 Go in Cincinnati. Metabolic
Testing will be offered before and after the seminar as well!`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      